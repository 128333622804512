import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

import {
  SectionIcon,
  LineBreak,
  SubscriptionOptionContainer,
  ButtonContainer,
  GradientButton,
  Spinner,
  CouponButton,
  FreeTrialDisclaimer,
} from 'components/Subscriptions/PricingPlansComponents.js'

const SubscriptionProduct = ({
  subscriptionProductTitle,
  subscriptionProductImageData,
  subscriptionProductDescriptionTranslationKey,
  onSubscriptionProductClick,
  subscriptionProductMonthlyName,
  subscriptionProductAnnualName,
  subscriptionProductMonthlyPrice,
  subscriptionProductAnnualPrice,
  isSubscriptionProductMonthlyButtonLoading,
  isSubscriptionProductAnnualButtonLoading,
  isSubscriptionButtonManage,
  shouldDisplayCouponButton,
  useMonthlyFreeTrial,
}) => {
  const { t } = useTranslation('subscriptionProducts')

  return (
    <SubscriptionOptionContainer role="presentation">
      <SectionIcon>
        <GatsbyImage
          alt={subscriptionProductTitle}
          image={subscriptionProductImageData}
        />
      </SectionIcon>
      <h4>{subscriptionProductTitle}</h4>
      <LineBreak />
      <p>{t(subscriptionProductDescriptionTranslationKey)}</p>
      <ButtonContainer>
        <>
          <GradientButton
            onClick={() =>
              onSubscriptionProductClick(
                subscriptionProductMonthlyName,
                isSubscriptionButtonManage,
                useMonthlyFreeTrial,
              )
            }
          >
            {isSubscriptionProductMonthlyButtonLoading ? (
              <Spinner />
            ) : isSubscriptionButtonManage ? (
              t('manage')
            ) : useMonthlyFreeTrial ? (
              t('freeTrial')
            ) : (
              subscriptionProductMonthlyPrice
            )}
          </GradientButton>
          {useMonthlyFreeTrial && !isSubscriptionButtonManage && (
            <FreeTrialDisclaimer>
              <p>
                {subscriptionProductMonthlyPrice} {t('after')}
              </p>
            </FreeTrialDisclaimer>
          )}
          {isSubscriptionButtonManage ? null : (
            <GradientButton
              onClick={() =>
                onSubscriptionProductClick(
                  subscriptionProductAnnualName,
                  isSubscriptionButtonManage,
                )
              }
              marginTop={useMonthlyFreeTrial ? '0' : null}
            >
              {isSubscriptionProductAnnualButtonLoading ? (
                <Spinner />
              ) : (
                subscriptionProductAnnualPrice
              )}
            </GradientButton>
          )}
        </>
      </ButtonContainer>
      {shouldDisplayCouponButton ? (
        <Link
          to="/payment/coupon"
          state={{ subscriptionName: subscriptionProductMonthlyName }}
        >
          <CouponButton>{t('useCoupon')}</CouponButton>
        </Link>
      ) : null}
    </SubscriptionOptionContainer>
  )
}

SubscriptionProduct.defaultProps = {
  useMonthlyFreeTrial: false,
}

SubscriptionProduct.propTypes = {
  subscriptionProductTitle: PropTypes.string.isRequired,
  subscriptionProductImageData: PropTypes.object.isRequired,
  subscriptionProductDescriptionTranslationKey: PropTypes.string.isRequired,
  onSubscriptionProductClick: PropTypes.func.isRequired,
  subscriptionProductMonthlyName: PropTypes.string.isRequired,
  subscriptionProductMonthlyPrice: PropTypes.string.isRequired,
  subscriptionProductAnnualName: PropTypes.string.isRequired,
  subscriptionProductAnnualPrice: PropTypes.string.isRequired,
  isSubscriptionProductMonthlyButtonLoading: PropTypes.bool.isRequired,
  isSubscriptionProductAnnualButtonLoading: PropTypes.bool.isRequired,
  isSubscriptionButtonManage: PropTypes.bool,
  shouldDisplayCouponButton: PropTypes.bool,
  useMonthlyFreeTrial: PropTypes.bool,
}

export default SubscriptionProduct
