import React, { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import Shape from 'shape-library'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useQueryParam, StringParam } from 'use-query-params'

import mq from 'styles/breakpoints'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import Layout from 'components/Layout'
import DownloadFluentWorlds from 'components/DownloadFluentWorlds'
import Subscriptions from 'components/Subscriptions'
import Loading from 'components/Loading'
import AppLoginHandler from 'components/AppLoginHandler'

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const UpdateUserNameContainer = styled.div`
  display: flex;
`

const UserSettingsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

const UserSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ShapeContainer = styled.div`
  align-self: flex-end;
  width: 500px;
  height: 500px;
  ${mq['md']} {
    display: none;
  }
`

const PrimaryButton = styled.button`
  display: inline-block;
  background-color: #003699;
  color: white;
  border-radius: 4px;
  border: none;
  font-size: 1.8rem;
  padding: 0.8rem;
  text-decoration: none;
  cursor: pointer;
`

const Account = () => {
  const MIN_USERNAME_LENGTH = 1
  const MAX_USERNAME_LENGTH = 15
  const { auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const { t, i18n, ready } = useTranslation('accountPage')

  const {
    updateUserName,
    username,
    productAccess,
    fetchUserProductAccess,
    userStateLoading,
  } = useContext(UserContext)
  const [input, setInput] = useState()
  const [app] = useQueryParam('app', StringParam)
  const [platform] = useQueryParam('platform', StringParam)

  useEffect(() => {
    if (!auth0Loading) {
      if (isAuthenticated) {
        fetchUserProductAccess()
      } else {
        loginWithRedirect({ ui_locales: i18n.language.substring(0, 2) })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading])

  const handleInputChange = (e) =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    })

  const changeUsername = async () => {
    if (
      input &&
      input.username &&
      input.username.length >= MIN_USERNAME_LENGTH &&
      input.username.length <= MAX_USERNAME_LENGTH
    ) {
      if (window.confirm(t('confirmUsernameChange'))) {
        if ((await updateUserName(input.username)) === true) {
          toast.dark(t('successUsernameChange'))
        }
      }
    } else {
      toast.error(
        t('invalidUsernameLengthError', {
          min: MIN_USERNAME_LENGTH,
          max: MAX_USERNAME_LENGTH,
        }),
      )
    }
  }

  const isFromApp = () => {
    return (
      typeof app !== 'undefined' &&
      (platform === 'ios' || platform === 'android')
    )
  }

  const isAccountStateLoading =
    auth0Loading || userStateLoading || !productAccess || !ready

  const shouldDisplayChooseSubscriptions =
    productAccess &&
    !productAccess.fluentworlds &&
    !productAccess.perfectaccent &&
    !productAccess.fluentworldsBundle

  return (
    <Layout>
      <AppLoginHandler />
      {isAccountStateLoading && <Loading />}
      {!isAccountStateLoading && (
        <AccountContainer>
          <DownloadFluentWorlds />
          {isFromApp()
            ? null
            : (shouldDisplayChooseSubscriptions ? (
                <h1
                  css={`
                    text-align: center;
                  `}
                >
                  {t('chooseSubscription')}
                </h1>
              ) : (
                <h1
                  css={`
                    text-align: center;
                  `}
                >
                  {t('manageSubscriptions')}
                </h1>
              )) && <Subscriptions />}
          <h1
            css={`
              text-align: center;
              margin-bottom: 1rem;
            `}
          >
            {t('accountSettings')}
          </h1>
          <UserSettingsWrapper>
            <UserSettingsContainer>
              <UpdateUserNameContainer>
                <input
                  type="text"
                  name="username"
                  placeholder={username}
                  onChange={handleInputChange}
                  css={`
                    margin-right: 1rem;
                    border-radius: 0.5rem;
                  `}
                />
                <PrimaryButton onClick={changeUsername}>
                  {t('updateUsername')}
                </PrimaryButton>
              </UpdateUserNameContainer>
            </UserSettingsContainer>
            <ShapeContainer>
              <Shape
                type="animatedillustrations"
                category="Abstract"
                name="Settings"
                primaryColor="#4A90E2"
                secondaryColor="#CACACA"
                size={500}
              />
            </ShapeContainer>
          </UserSettingsWrapper>
        </AccountContainer>
      )}
    </Layout>
  )
}

export default Account
