import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'

import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import SubscriptionProduct from './SubscriptionProduct'
import { SubscriptionsContainer } from 'components/Subscriptions/PricingPlansComponents.js'

const getSubscriptionProductImages = graphql`
  {
    fluentworldsProductImage: file(
      relativePath: { eq: "png/start_fwshowcase.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, height: 100)
      }
    }
    perfectaccentProductImage: file(
      relativePath: { eq: "jpg/start_pashowcase.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, height: 100)
      }
    }
    bundleProductImage: file(
      relativePath: { eq: "png/bundle-subscription-icon.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, height: 140)
      }
    }
  }
`

const FLUENTWORLDS_PRODUCT_TITLE = 'FluentWorlds'
const PERFECTACCENT_PRODUCT_TITLE = 'PerfectAccent'
const BUNDLE_PRODUCT_TITLE = 'Bundle'

const sanitzeQueryParam = (paramArg) => {
  return paramArg.split(' ').join('-')
}

const desanitzeQueryParam = (paramArg) => {
  return paramArg.split('-').join(' ')
}

const doesUserHaveProductAccess = (
  subscriptionProductName,
  userProductAccess,
) => {
  if (userProductAccess.fluentworldsBundle) {
    return subscriptionProductName.includes(BUNDLE_PRODUCT_TITLE)
  } else if (
    userProductAccess.perfectaccent &&
    userProductAccess.fluentworlds
  ) {
    return (
      subscriptionProductName.includes(PERFECTACCENT_PRODUCT_TITLE) ||
      subscriptionProductName.includes(FLUENTWORLDS_PRODUCT_TITLE)
    )
  } else if (userProductAccess.fluentworlds) {
  } else if (userProductAccess.perfectaccent) {
    return subscriptionProductName.includes(PERFECTACCENT_PRODUCT_TITLE)
  } else if (userProductAccess.fluentworlds) {
    return subscriptionProductName.includes(FLUENTWORLDS_PRODUCT_TITLE)
  }
}

const Subscriptions = ({ subscriptionsToRender }) => {
  const { t, i18n } = useTranslation('subscriptionProducts')
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const { productAccess, fetchUserProductAccess } = useContext(UserContext)
  const {
    fetchAvailableSitePlans,
    createStripeSubscriptionSession,
    createBillingPortalSession,
    subscriptions,
    subscriptionStateLoading,
  } = useContext(SubscriptionContext)

  useEffect(() => {
    if (isAuthenticated && subscriptions.length === 0) {
      fetchAvailableSitePlans()

      if (!productAccess) {
        fetchUserProductAccess()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, productAccess])

  const [subscriptionNameQueryParam] = useQueryParam(
    'subscription-name',
    StringParam,
  )
  const [freeTrialQueryParam] = useQueryParam('free-trial', StringParam)
  useEffect(() => {
    if (
      subscriptionNameQueryParam !== undefined &&
      subscriptions &&
      subscriptions.length > 0
    ) {
      const desantizedSubscriptionName = desanitzeQueryParam(
        subscriptionNameQueryParam,
      )

      if (
        !doesUserHaveProductAccess(desantizedSubscriptionName, productAccess)
      ) {
        setSelectedSubscriptionProduct(desantizedSubscriptionName)
        createStripeSubscriptionSession(
          desantizedSubscriptionName,
          null,
          freeTrialQueryParam && freeTrialQueryParam === 'true'
            ? NUMBER_OF_FREE_TRIAL_DAYS
            : null,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions, subscriptionNameQueryParam, productAccess])

  const subscriptionProductImageData = useStaticQuery(
    getSubscriptionProductImages,
  )

  // TODO: Possibly make these config driven instead?
  const FLUENTWORLDS_MONTHLY_NAME = 'FluentWorlds Monthly'
  const FLUENTWORLDS_MONTHLY_PRICE = `$9.99 USD / ${t('monthly')}`
  const FLUENTWORLDS_ANNUAL_NAME = 'FluentWorlds Annual'
  const FLUENTWORLDS_ANNUAL_PRICE = `$99.99 USD / ${t('yearly')}`
  const PERFECTACCENT_MONTHLY_NAME = 'PerfectAccent Monthly'
  const PERFECTACCENT_MONTHLY_PRICE = `$9.99 USD / ${t('monthly')}`
  const PERFECTACCENT_ANNUAL_NAME = 'PerfectAccent Annual'
  const PERFECTACCENT_ANNUAL_PRICE = `$99.99 USD / ${t('yearly')}`
  const BUNDLE_MONTHLY_NAME = 'FluentWorlds Bundle Monthly'
  const BUNDLE_MONTHLY_PRICE = `$17.99 USD / ${t('monthly')}`
  const BUNDLE_ANNUAL_NAME = 'FluentWorlds Bundle Annual'
  const BUNDLE_ANNUAL_PRICE = `$179.99 USD / ${t('yearly')}`

  const AUTH0_LOCK_SIGN_UP = 'signUp'
  const ACCOUNT_PATH = '/account/'
  const QUERY_PARAM_SUBSCRIPTION_NAME = '?subscription-name='
  const QUERY_PARAM_FREE_TRIAL = '&free-trial='

  const NUMBER_OF_FREE_TRIAL_DAYS = 7

  const [
    selectedSubscriptionProduct,
    setSelectedSubscriptionProduct,
  ] = useState('')
  const onSubscriptionProductClick = async (
    subscriptionProductName,
    isSubscriptionButtonManage,
    useMonthlyFreeTrial = false,
  ) => {
    setSelectedSubscriptionProduct(subscriptionProductName)

    if (isAuthenticated) {
      if (isSubscriptionButtonManage) {
        const billingSessionUrl = await createBillingPortalSession()

        if (typeof window !== 'undefined' && billingSessionUrl !== undefined) {
          window.location.href = billingSessionUrl
        }
      } else {
        createStripeSubscriptionSession(
          subscriptionProductName,
          null,
          useMonthlyFreeTrial ? NUMBER_OF_FREE_TRIAL_DAYS : null,
        )
      }
    } else {
      const isFreeTrial = useMonthlyFreeTrial
      loginWithRedirect({
        mode: AUTH0_LOCK_SIGN_UP,
        redirect_uri:
          process.env.GATSBY_WEBSITE_URL +
          ACCOUNT_PATH +
          QUERY_PARAM_SUBSCRIPTION_NAME +
          sanitzeQueryParam(subscriptionProductName) +
          QUERY_PARAM_FREE_TRIAL +
          isFreeTrial,
        ui_locales: i18n.language.substring(0, 2),
      })
    }
  }

  const isProductAccessInitializing = isAuthenticated && !productAccess
  const isFluentWorldsMonthlyButtonLoading =
    isProductAccessInitializing ||
    (subscriptionStateLoading &&
      selectedSubscriptionProduct === FLUENTWORLDS_MONTHLY_NAME)
  const isFluentWorldsAnnualButtonLoading =
    isProductAccessInitializing ||
    (subscriptionStateLoading &&
      selectedSubscriptionProduct === FLUENTWORLDS_ANNUAL_NAME)
  const isPerfectAccentMonthlyButtonLoading =
    isProductAccessInitializing ||
    (subscriptionStateLoading &&
      selectedSubscriptionProduct === PERFECTACCENT_MONTHLY_NAME)
  const isPerfectAccentAnnualButtonLoading =
    isProductAccessInitializing ||
    (subscriptionStateLoading &&
      selectedSubscriptionProduct === PERFECTACCENT_ANNUAL_NAME)
  const isBundleMonthlyButtonLoading =
    isProductAccessInitializing ||
    (subscriptionStateLoading &&
      selectedSubscriptionProduct === BUNDLE_MONTHLY_NAME)
  const isBundleAnnualButtonLoading =
    isProductAccessInitializing ||
    (subscriptionStateLoading &&
      selectedSubscriptionProduct === BUNDLE_ANNUAL_NAME)

  const isFluentWorldsSubscriptionButtonManage =
    productAccess && productAccess.fluentworlds
  const isPerfectAccentSubscriptionButtonManage =
    productAccess && productAccess.perfectaccent
  const isBundleSubscriptionButtonManage =
    productAccess && productAccess.fluentworldsBundle

  const shouldDisplayFluentWorldsCouponButton =
    isAuthenticated &&
    !isFluentWorldsMonthlyButtonLoading &&
    !isFluentWorldsAnnualButtonLoading &&
    !isFluentWorldsSubscriptionButtonManage
  const shouldDisplayPerfectAccentCouponButton =
    isAuthenticated &&
    !isPerfectAccentMonthlyButtonLoading &&
    !isPerfectAccentAnnualButtonLoading &&
    !isPerfectAccentSubscriptionButtonManage
  const shouldDisplayBundleCouponButton =
    isAuthenticated &&
    !isBundleMonthlyButtonLoading &&
    !isBundleAnnualButtonLoading &&
    !isBundleSubscriptionButtonManage

  return (
    <SubscriptionsContainer>
      {subscriptionsToRender.fluentworlds && (
        <SubscriptionProduct
          subscriptionProductTitle={FLUENTWORLDS_PRODUCT_TITLE}
          subscriptionProductImageData={
            subscriptionProductImageData.fluentworldsProductImage
              .childImageSharp.gatsbyImageData
          }
          subscriptionProductDescriptionTranslationKey="fluentworldsSubscriptionDescription"
          onSubscriptionProductClick={onSubscriptionProductClick}
          subscriptionProductMonthlyName={FLUENTWORLDS_MONTHLY_NAME}
          subscriptionProductAnnualName={FLUENTWORLDS_ANNUAL_NAME}
          subscriptionProductMonthlyPrice={FLUENTWORLDS_MONTHLY_PRICE}
          subscriptionProductAnnualPrice={FLUENTWORLDS_ANNUAL_PRICE}
          isSubscriptionProductMonthlyButtonLoading={
            isFluentWorldsMonthlyButtonLoading
          }
          isSubscriptionProductAnnualButtonLoading={
            isFluentWorldsAnnualButtonLoading
          }
          isSubscriptionButtonManage={isFluentWorldsSubscriptionButtonManage}
          shouldDisplayCouponButton={shouldDisplayFluentWorldsCouponButton}
          useMonthlyFreeTrial={true}
        />
      )}
      {subscriptionsToRender.perfectaccent && (
        <SubscriptionProduct
          subscriptionProductTitle={PERFECTACCENT_PRODUCT_TITLE}
          subscriptionProductImageData={
            subscriptionProductImageData.perfectaccentProductImage
              .childImageSharp.gatsbyImageData
          }
          subscriptionProductDescriptionTranslationKey="perfectaccentSubscriptionDescription"
          onSubscriptionProductClick={onSubscriptionProductClick}
          subscriptionProductMonthlyName={PERFECTACCENT_MONTHLY_NAME}
          subscriptionProductAnnualName={PERFECTACCENT_ANNUAL_NAME}
          subscriptionProductMonthlyPrice={PERFECTACCENT_MONTHLY_PRICE}
          subscriptionProductAnnualPrice={PERFECTACCENT_ANNUAL_PRICE}
          isSubscriptionProductMonthlyButtonLoading={
            isPerfectAccentMonthlyButtonLoading
          }
          isSubscriptionProductAnnualButtonLoading={
            isPerfectAccentAnnualButtonLoading
          }
          isSubscriptionButtonManage={isPerfectAccentSubscriptionButtonManage}
          shouldDisplayCouponButton={shouldDisplayPerfectAccentCouponButton}
        />
      )}
      {subscriptionsToRender.bundle && (
        <SubscriptionProduct
          subscriptionProductTitle={BUNDLE_PRODUCT_TITLE}
          subscriptionProductImageData={
            subscriptionProductImageData.bundleProductImage.childImageSharp
              .gatsbyImageData
          }
          subscriptionProductDescriptionTranslationKey="bundleSubscriptionDescription"
          onSubscriptionProductClick={onSubscriptionProductClick}
          subscriptionProductMonthlyName={BUNDLE_MONTHLY_NAME}
          subscriptionProductAnnualName={BUNDLE_ANNUAL_NAME}
          subscriptionProductMonthlyPrice={BUNDLE_MONTHLY_PRICE}
          subscriptionProductAnnualPrice={BUNDLE_ANNUAL_PRICE}
          isSubscriptionProductMonthlyButtonLoading={
            isBundleMonthlyButtonLoading
          }
          isSubscriptionProductAnnualButtonLoading={isBundleAnnualButtonLoading}
          isSubscriptionButtonManage={isBundleSubscriptionButtonManage}
          shouldDisplayCouponButton={shouldDisplayBundleCouponButton}
        />
      )}
    </SubscriptionsContainer>
  )
}

Subscriptions.defaultProps = {
  subscriptionsToRender: {
    fluentworlds: true,
    perfectaccent: true,
    bundle: true,
  },
}

Subscriptions.propTypes = {
  subscriptionsToRender: PropTypes.shape({
    fluentworlds: PropTypes.bool,
    perfectaccent: PropTypes.bool,
    bundle: PropTypes.bool,
  }),
}

export default Subscriptions
